import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BankService} from 'app/blocks/service/api/bank.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {Bank, IBank} from 'app/blocks/model/bank.model';
import {UsersService} from 'app/blocks/service/api/users.service';
import {IUserEntity, UserEntity} from 'app/blocks/model/user.model';

@Injectable({
    providedIn: 'root'
})
export class UserResource extends AbstractEntityResource<IUserEntity> {
    constructor(usersService: UsersService, router: Router) {
        super(
            Resource.USERS,
            usersService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.USERS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.USERS),
            (entity?) => new UserEntity(entity),
            (entity) => entity.id,
            'domain'
        );
    }
}
