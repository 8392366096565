import {Branch} from 'app/blocks/model/branch.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IBranch} from 'app/blocks/model/branch.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {IUserEntity, UserEntity} from 'app/blocks/model/user.model';
import {SERVER_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/blocks/util/request-util';

@Injectable({
    providedIn: 'root'
})
export class UsersService extends EntityCrudService<IUserEntity> {
    constructor(http: HttpClient) {
        super(http, Resource.USERS, (obj?) => new UserEntity(obj));
    }

    public getUsers = (): Promise<any> => {
        const options = createRequestOption({
            page: 0,
            size: 5000,
            sort: 'id,asc'
        });
        return this.http
            .get<any>(this._resourceUrl, {params: options})
            .toPromise()
            .then((responseEntities) => responseEntities.map((responseEntity) => this._entityCreator(responseEntity)));
    };
}
