import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IFranchise} from 'app/blocks/model/franchise.model';

export interface IUserEntity extends IBaseEntity {
    id?: any;
    login?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    activated?: boolean;
    langKey?: string;
    authorities?: any[];
    roles?: any[];
    password?: string;
    franchise?: IFranchise;
    viewAllFranchises?: boolean;
}

export class UserEntity extends BaseEntity implements IUserEntity {
    public id?: any;
    public login?: string;
    public firstName?: string;
    public lastName?: string;
    public email?: string;
    public activated?: boolean;
    public langKey?: string;
    public authorities?: any[];
    public roles?: any[];

    franchise?: IFranchise;
    viewAllFranchises?: boolean;

    // outbound only
    public newPassword?: string;

    constructor(user?: any) {
        super();
        if (user) {
            this.id = user.id;
            this.login = user.login;
            this.firstName = user.firstName;
            this.lastName = user.lastName;
            this.email = user.email;
            this.activated = user.activated;
            this.langKey = user.langKey;
            this.roles = user.roles;
            this.authorities = user.authorities;
            this.newPassword = user.newPassword;
            this.franchise = user.franchise;
            this.viewAllFranchises = user.viewAllFranchises;
        }
    }

    clone(): IBaseEntity {
        return new UserEntity(this);
    }
}
